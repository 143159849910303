/**
 * inverted Theme file for fontIconPicker
 * {@link https://github.com/micc83/fontIconPicker}
 */
/* Main Container */
.fip-inverted.icons-selector {
	font-size: 16px;
	color: #aaa;
}
/* Icon selector */
.fip-inverted.icons-selector .selector {
	border: 1px solid #111;
	background-color: #333;
}
/* Selector open button */
.fip-inverted.icons-selector .selector-button {
	background-color: #222;
	border-left: 1px solid #111;
}

/* Selector open button hover */
.fip-inverted.icons-selector .selector-button:hover {
	background-color: #000;
}
.fip-inverted.icons-selector .selector-button:hover i {
	color: #fff;
}
/* Selector open button icon */
.fip-inverted.icons-selector .selector-button i {
	color: #eee;
}
/* Selected icon */
.fip-inverted.icons-selector .selected-icon i {
	color: #ccc;
	text-shadow: 0 0 1px #000;
}
/* IconPicker Popup */
.fip-inverted.icons-selector .selector-popup {
	-moz-box-shadow: 0 1px 1px rgba(255,255,255,0.04);
	-webkit-box-shadow: 0 1px 1px rgba(255,255,255,0.04);
	box-shadow: 0 1px 1px rgba(255,255,255,0.04);
	border: 1px solid #111;
	background-color: #101010;
}
/* Search input & category selector */
.fip-inverted.icons-selector .selector-search input[type="text"],
.fip-inverted.icons-selector .selector-category select {
	border: 1px solid #111;
	background: #333;
	color: #aaa;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
}
/* Search input placeholder */
.fip-inverted.icons-selector input::-webkit-input-placeholder { color:#aaa; }
.fip-inverted.icons-selector input:-moz-placeholder { color:#aaa; }
.fip-inverted.icons-selector input::-moz-placeholder { color:#aaa;}
.fip-inverted.icons-selector input:-ms-input-placeholder { color:#aaa !important; }
/* Search and cancel icon */
.fip-inverted.icons-selector .selector-search i {
	color: #aaa;
}
/* Icon Container inside Popup */
.fip-inverted.icons-selector .fip-icons-container {
	background-color: #333;
	border: 1px solid #111;
}
/* Icon container loading */
.fip-inverted.icons-selector .fip-icons-container .loading {
	color:#aaa;
}
/* Single icon box */
.fip-inverted.icons-selector .fip-box {
	border: 1px solid #111;
}
/* Single icon box hover */
.fip-inverted.icons-selector .fip-box:hover {
	background-color: #000;
	color: #eee;
}

/* Pagination and footer icons */
.fip-inverted.icons-selector .selector-footer, .fip-inverted.icons-selector .selector-footer i {
	color: #aaa;
}

/* Pagination arrows icons hover */
.fip-inverted.icons-selector .selector-arrows i:hover {
	color: #000;
}
/* Currently selected icon color */
.fip-inverted.icons-selector span.current-icon, .fip-inverted.icons-selector span.current-icon:hover {
	background-color: #000;
	color: #fff;
}
/* No icons found */
.fip-inverted.icons-selector .icons-picker-error i:before,
.fip-inverted.icons-selector .fip-icon-block:before {
	color: #663333;
	text-shadow: none;
}
