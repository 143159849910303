
.history-item.ng-enter {
    -webkit-animation: fadeInDown 0.4s;
    -moz-animation: fadeInDown 0.4s;
    -ms-animation: fadeInDown 0.4s;
    animation: fadeInDown 0.4s;
}

.history-item.ng-leave {
    -webkit-animation: fadeOutUp 0.4s;
    -moz-animation: fadeOutUp 0.4s;
    -ms-animation: fadeOutUp 0.4s;
    animation: fadeOutUp 0.4s;
}

.alert-item.ng-enter {
    -webkit-animation: fadeInUp 0.5s;
    -moz-animation: fadeInUp 0.5s;
    -ms-animation: fadeInUp 0.5s;
    animation: fadeInUp 0.5s;
}

.alert-item.ng-leave {
    -webkit-animation: fadeOutDown 0.5s;
    -moz-animation: fadeOutDown 0.5s;
    -ms-animation: fadeOutDown 0.5s;
    animation: fadeOutDown 0.5s;
}

.slideLeft.ng-enter {
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

@-moz-keyframes spinPulse {
    0% {
        -moz-transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px #ff5500;
    }
    50% {
        -moz-transform: rotate(145deg);
        opacity: 1;
    }
    100% {
        -moz-transform: rotate(-320deg);
        opacity: 0;
    }
}

@-moz-keyframes spinoffPulse {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinPulse {
    0% {
        -webkit-transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px #ff5500;
    }
    50% {
        -webkit-transform: rotate(145deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: rotate(-320deg);
        opacity: 0;
    }
}

@-webkit-keyframes spinoffPulse {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}


@-webkit-keyframes bookmarksInFromLeft {
    0% {
        -webkit-transform: translateX(-25%);
    }
    40% {
        -webkit-transform: translateX(-15%);
    }
    100% {
        -webkit-transform: translateX(0px);
    }
}
@-moz-keyframes bookmarksInFromLeft {
    0% {
        transform: translateX(-320px);
    }
    40% {
        transform: translateX(-100px);
    }
    100% {
        transform: translateX(0px);
    }
}


