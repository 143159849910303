.cron-button {
    height: 16px;
    padding-left: 20px;
    margin-left: 5px;
    background-repeat: no-repeat; 
    background-position: center center; 
    cursor: pointer;
}
.cron-button-save {
    background-image: url('img/disk.png');
}
.cron-changed {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fdd;
}
.cron-controls {
    margin-left: 10px;
    color: #c77;
    font-size: 0.9em;
}
.cron-controls > span.cron-loading {
    background-image: url('img/loading.gif');
}