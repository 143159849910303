html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 13px;
  color: #4c4f53;
  background-color: #edecec;
  font-family: 'Source Sans Pro', sans-serif;
}

#timeline {
  height: 300px;
}
.header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  border: none;
}

.pointer {
  cursor: pointer;
}

.CodeMirror {
  border: 1px solid #eee;
  height: auto;
  min-height: 50px;

}

.jsonView .CodeMirror {
  height: 200px !important;
}

.CodeMirror-scroll {
  overflow: auto;
}

.CodeMirror-wrap .CodeMirror-scroll {
  overflow: auto;
}

.queryText {

}

.nomargin {
  margin: 0px !important;
}

table.pointer > tbody > tr > td input, table.pointer > tbody > tr > td select {

  width: 110px;
}

.ocontainer-body div.scroll-indexes {
  max-height: none;
}

div.scroll-editclass, div.scroll-indexes {
  overflow: auto;
  margin-top: 26px;
  /*border-top: 1px solid grey;*/
  /*border-bottom: 1px solid grey;*/
}

.accordion-scroll {
  height: 150px;
  overflow: auto;
}

.sidenav {
  background-color: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.067);
  padding: 0;
}

.sidenav > li:first-child > a {
  border-radius: 6px 6px 0 0;
}

.sidenav > .active > a {
  border: 0 none;
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.1) inset, -1px 0 0 rgba(0, 0, 0, 0.1) inset;
  padding: 9px 15px;
  position: relative;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.sidenav > li > a {
  border: 1px solid #E5E5E5;
  display: block;
  margin: 0 0 -1px;
  padding: 8px 14px;
}

.sidenav .active .icon-chevron-right, .sidenav .active a:hover .icon-chevron-right {
  background-image: url("../img/glyphicons-halflings-white.png");
  opacity: 1;
}

.sidenav .icon-chevron-right {
  float: right;
  margin-right: -6px;
  margin-top: 2px;
  opacity: 0.25;
}

.doc-header {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color -moz-use-text-color #E5E5E5;
  border-image: none;
  color: #333333;
  display: block;
  font-size: 21px;
  line-height: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 0px !important;

}

.doc-header > label {
  color: #333333;
  font-size: 21px;
  line-height: 40px;
}

div.vertex form.bs-docs-example:after {
  content: "Vertex";
}

div.edge form.bs-docs-example:after {
  content: "Edge";
}

.record-header {
  background-color: #F5F5F5;
  border: 1px solid #DDDDDD;
  border-radius: 4px 0 4px 0;
  color: #9DA0A4;
  font-size: 13px;
  font-weight: bold;
  left: -1px;
  padding: 1px 4px;
  position: absolute;
  top: -1px;
}

.record-header {

}

.query-header > h5 {
  margin: 5px 0;
}

.query-header > span {
  position: absolute;
  top: -13px;
  z-index: -1;
}

.search-query {
  margin-bottom: 10px;
}

.query-header {
  background-color: #F5F5F5;
  border: 1px solid #DDDDDD;
  color: #9DA0A4;
  font-size: 12px;
  font-weight: bold;
  left: -1px;
  padding: 2px 5px;
  position: absolute;
  top: -1px;
  max-width: 90%;
}

.query-header a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.search-history {
  margin: 0;
}

.query-actions {
  border: 1px solid #DDDDDD;
  color: #9DA0A4;
  font-size: 13px;
  font-weight: bold;
  right: -1px;
  padding: 5px 8px;
  position: absolute;
  top: -1px;
}

.ocontainer {
  background-color: #FFFFFF;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
  /*box-shadow: 0 1px 1px rgba(0,0,0,.05);*/
  border: 1px solid #DDDDDD;
  margin: 15px 0;
  position: relative;
}

.ocontainer-body {
  padding: 10px 10px 0px;

}

.query-container {
  background-color: #FFFFFF;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05);
  /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);*/
  /*box-shadow: 0 1px 1px rgba(0,0,0,.05);*/
  /*border: 1px solid #DDDDDD;*/
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}

.query-body {
  padding: 40px 19px 14px;
  overflow: auto;

}

.master-query .query-header {
  font-size: 11px;

}

.master-query .query-body .CodeMirror {
  border: none;
}

.master-query .query-actions .btn-toolbar {
  margin-bottom: 0px;
  margin-top: 0px;
}

.master-query .query-actions {
  border: none;
  bottom: -1px;
  top: auto;
}

.master-query .query-info {
  background-color: #F5F5F5;
  height: 40px;
  border-radius: 0px 0px 4px 4px;
  padding: 0;
  border-top: none;
}

.graph-query .query-info {
  background-color: #F5F5F5;
  height: 40px;
  padding: 0;
  padding-top: 2px;
  border: 1px solid #DEDEDE;
  border-top: none;
  border-bottom: none;
}

.master-query .query-body {
  padding: 0px 0px 0px;
  height: 100%;
}

.master-query .query-body > form {
  margin-bottom: 0;
}

.master-query .query-info > form {
  margin-right: 10px;
  color: #333333;
}

.master-query {
  margin-top: 0px;
}

.input-xxsmall {
  width: 30px;
}

.query-info {
  background-color: #FFFFFF;
  border-top: 1px solid #E7E9EB;
  font-size: 13px;
  height: 39px;
  padding: 0px 19px 14px;
  /*line-height: 39px;*/
}

.query-info .row-fluid [class*="span"] {
  padding-top: 8px;
}

form.record-header {

}

.record {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px 4px 4px 4px;
  margin: 15px 0;
  padding: 0px 19px 14px;
  position: relative;
}

.createEdge {
  width: 80%; /* desired relative width */
  left: 10%; /* (100%-width)/2 */
  /* place center */
  margin-left: auto;
  margin-right: auto;
}

.createEdge .modal-body {
  width: 96%;
  max-height: 200px;
}

.createEdge .modal-body .CodeMirror {
  height: 100px;
}

.createEdge .modal-body .table {
  width: 96%;
  height: 100px;
  overflow: auto;
}

.editEdge {
  width: 60%; /* desired relative width */
  left: 20%; /* (100%-width)/2 */
  /* place center */
  margin-left: auto;
  margin-right: auto;
}

.css-form input.ng-invalid.ng-dirty {
  background-color: #FA787E;
}

.css-form input.ng-valid.ng-dirty {
  background-color: #78FA89;
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #cccccc;
  content: "/\00a0";
}

.input-xlarge-auto {
  width: 90%;
}

form .required {
  font-weight: bold;
}

form .required label:after {
  color: #e32;
  content: '*';
  display: inline;
}

#loader {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: grey;
  opacity: .8;
}

.spinner-start {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #d3d3d3;
  opacity: .4;
}

.ajax-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px; /* -1 * image width / 2 */
  margin-top: -100px; /* -1 * image height / 2 */
  display: block;
}

#spinner, .spinner, #spinner-popup, .spinner-popup {
  position: absolute;
  left: 50%;
  top: 50%;
}

.fade-hide, .fade-show {
  -webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
  -moz-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
  -o-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
  transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
}

.fade-hide {
  opacity: 1;
}

.fade-hide.fade-hide-active {
  opacity: 0;
}

.fade-show {
  opacity: 0;
}

.fade-show.fade-show-active {
  opacity: 1;
}

.tag-rid {
  -moz-box-sizing: border-box;
  background: none repeat scroll 0 0 #E2E6F0;
  border: 1px solid #9DACCC;
  border-radius: 2px 2px 2px 2px;
  color: #000000;
  cursor: pointer;
  float: left;
  font: 12px "lucida grande", tahoma, verdana, arial, sans-serif;
  height: 16px;
  margin: 0 2px 2px 0;
  padding: 0 17px 0 3px;
  position: relative;
}

ul a > i {
  vertical-align: inherit !important;
  margin-top: auto !important;
}

button.exportButton {
  background: none repeat scroll 0 0 #3399FF;
  border: 1px solid #2E6E9E;
  border-radius: 10px 10px 10px 10px;
  color: #333333;
  cursor: pointer;
  direction: ltr;
  font-weight: bold;
  height: 2.5em;
  line-height: 2.5em;
  overflow: hidden;
  position: relative;
  text-align: center !important;
  width: 15em;
}

.horizontallist li {
  display: inline;
}

ul.horizontallist, ol.horizontallist {
  margin: 0px !important;
  padding: 0px;
}

.functions_consoleresult textarea {
  width: 400px;
}

.functions_list {
  min-height: 400px;
  max-height: 800px;
  overflow-y: scroll;
}

.navbar.navbar-inverse .navbar-inner {
  background: none repeat scroll 0 0 #262626;
  border-bottom: 3px solid #FF6600;
  text-shadow: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);

}

.panelAlerts {
  margin-top: 5px;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 9999;
}

.container-alerts {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 80%;
  text-align: center;
  z-index: 9999;
}

.queryHelp {
  list-style: none;
  margin: 0;
}

.queryHelp {
  background-color: #F5F5F5;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 4px 4px;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 10px;
  padding: 9.5px;
  word-break: break-all;
  word-wrap: break-word;
}

.viewJson .CodeMirror {
  height: 300px;
  margin-bottom: 20px;
}

.label-data {
  background-color: #F38630;
  color: #555555;
  text-align: center;

}

.label-data span {
  margin-top: 5px;
  display: block;
}

.label-free span {
  margin-top: 5px;
  display: block;
}

.label-free {
  background-color: #E0E4CC;
  color: #555555;
  text-align: center;

}

.table > thead:first-child > tr:first-child > th {
  border-top: 2px solid #e8e9e9;
}

.table > thead > tr > th:first-child {
  border-left: none;
}

.table > thead > tr > th:last-child {
  border-right: none;
}

.table > thead > tr > th {
  /*background: none repeat scroll 0 0 #DDDDDD;*/
  /*border-color: #C4C4C4;*/
  /*padding-bottom: 2px;*/
  /*padding-top: 2px;*/
  /*position: relative;*/

  background: 0 0;
  font-size: 12px;
  border-width: 1px 1px 1px 0;
  -webkit-border-image: -webkit-linear-gradient(bottom, #d7dbdb, #fff) 1 100%;
  border-bottom: 2px solid #e8e9e9;
  border-top: 2px solid #e8e9e9;

  border-left: 1px solid #e8e9e9;
  border-right: 1px solid #e8e9e9;
}

.popoverForm {
  margin: 0;
}

.CodeMirror-hints {
  z-index: 1200;
}

.pagination_select {
  margin-top: 0px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a, .pagination > li > span {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
}

.pagination > .disabled > span, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  background-color: #FFFFFF;
  border-color: #DDDDDD;
  color: #999999;
  cursor: not-allowed;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  margin-left: 0;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

ul.arrayrid {
  list-style-type: none;
}

ul.arrayrid > li {
  display: inline-block;
}

.hide {
  display: none;
}

.showMore {
  display: none;
}

.config .popover {
  top: 28px !important;
  left: -140px !important;
}

.config .popover.bottom .arrow {
  left: 90%;
  margin-left: -30px;
  top: -10px;
}

.navbar .nav > li > a {
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.container-notification {
  position: fixed;
  width: 100%;
  z-index: 1051;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.container-notification .alert {
  margin-bottom: 0px;
}

.ng-table th {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.bookmarks .popover {
  left: -210px !important;
  top: 28px !important;
}

.bookmarks .popover.bottom .arrow {
  left: 90%;
  margin-left: -15px;
  top: -10px;
}

.animate-switch.animate {
  -webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
  transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.animate-switch.ng-leave.leave-active,
.animate-switch.enter {
  top: -50px;
}

.animate-switch.leave,
.animate-switch.enter.enter-active {
  top: 0;
}

.bookmarks-container .query-body {
  padding: 30px 19px 14px;
}

.context-navigator {
  position: relative;
}

.context-navigator > a {
  position: absolute;
  right: -1px;
}

.show {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -ms-transform: translateX(0px);
  animation-name: bookmarksInFromLeft;
  -webkit-animation-name: bookmarksInFromLeft;
  animation-duration: 1s;
  -webkit-animation-duration: 0.2s;
}

@-moz-keyframes bookmarksInFromLeft {
  0% {
    transform: translateX(-260px);
  }
  40% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes bookmarksInFromLeft {
  0% {
    -webkit-transform: translateX(-260px);
  }
  40% {
    -webkit-transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0px);
  }
}

.search-bookmarks {
  padding-left: 5px;
}

.bookmarks-list {
  padding-left: 5px;
  padding-right: 5px;
}

.bookmarks-item {
  background-color: #2E2F33 !important;
  color: #DFE0E6 !important;
  border-width: 0 0 2px;
  transition: background-color 100ms ease-in-out 0s;

  background-clip: padding-box;
  border-style: none none solid;
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0);
}

.bookmarks-list > li > a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none !important;
  color: #ffffff;

}

.bookmarks-list > li > a:HOVER {
  background-color: #46484E !important;
}

.bookmarks-list > li:first-child > a:HOVER {
  border-radius: 0 0;
}

.bookmarks-list > li:last-child > a:HOVER {
  border-radius: 0 0;
}

.book-badge {
  margin-right: 2px;
}

.bookmarks-folder {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  writing-mode: tb-rl;
  position: absolute;
  right: -51px;
  top: 200px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 13px;
  padding-bottom: 6px;
  z-index: 1001;
}

.browse-container {
  display: block;
  height: 100%;
}

.bookmarks-actions {
  color: #9DA0A4;
  font-size: 10px;
  font-weight: bold;
  padding-right: 7px;
  margin-top: -2px;
  position: absolute;
  right: -1px;
}

.bookmarks-tags {
  font-size: 10px;
  font-weight: bold;
  padding-top: 50px;
  right: 2px;
  position: absolute;
}

.circle-container {
  position: absolute;
  top: 40%;
  left: 47%;
  z-index: 1100;
}

.circle {
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid #FF6600;
  opacity: .9;
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  box-shadow: 0 0 35px #FF5500;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  -moz-animation: spinPulse 1s infinite ease-in-out;
  -webkit-animation: spinPulse 1s infinite linear;
}

.circle-interrupt {
  position: fixed;
  top: 55%;
  left: 45%;
  opacity: 1;
  z-index: 9999;
}

.circle-interrupt-stop {
  display: none;
}

.icon-circle-interrupt {
  background: url("../img/stop_icon.png");
  height: 32px;
  width: 32px;
  background-size: cover;
}

.circle1 {
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid #FF6600;
  opacity: .9;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  box-shadow: 0 0 15px #ff5500;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  top: -65px;
  -moz-animation: spinoffPulse 1s infinite linear;
  -webkit-animation: spinoffPulse 1s infinite linear;
}

.circle-start {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.92);
  opacity: .6;
}

.circle-stop {
  display: none;
}

.query-bookmark {
  border: 1px solid #DDDDDD;
  color: #9DA0A4;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 7px;
  position: absolute;
  right: -1px;
  z-index: 200;
}

#fixed {
  /*height: 600px;*/
  /*width: 100%;*/
  /*overflow: auto;*/
}

span.badge-edge {
  display: inline-block;
  margin-right: 1px;
}

.badge-edge > a {
  color: white;
}

.edge-table {
  width: 100%;
}

.edge-table .badge-edge {
  /*width: 100%;*/
}

.panelAlerts .alert {
  padding: 10px;

}

.panelAlerts .alert-error {
  background: #cf280e;
  color: white;
  text-shadow: none;
  box-shadow: 0 0 2px #000000;
  border: none;
  border-radius: 0 0;
}

.panelAlerts .alert-info {
  background: #368a54;
  color: white;
  text-shadow: none;
  box-shadow: 0 0 2px #000000;
  border: none;
  border-radius: 0 0;
}

.panelAlerts .alert-warning {
  background: #ff8000;
  color: white;
  text-shadow: none;
  box-shadow: 0 0 2px #000000;
  border: none;
  border-radius: 0 0;
}

.panelAlerts .alert .close {
  position: relative;
  color: white;
  opacity: 1;
}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.navbar .brand {
  padding: 2px 10px;
}

.vertex-fields p {
  text-overflow: ellipsis;
  overflow: hidden;
}

.query-profiler .row {
  padding-top: 20px;
}

.st-sort-descent:before {
  content: '\25BC';
}

.st-sort-ascent:before {
  content: '\25B2';
}

.table-profiles th {
  cursor: pointer;
}

.navbar .nav > li > a.disabled {
  color: #AAAAAA;
  text-decoration: none;
}

.navbar .nav > li > a.disabled div.popover {
  color: #333;
  text-shadow: none;
  min-width: 250px;

}

.centering {
  float: none;
  margin: 0 auto;
}

.row-centered {
  text-align: center;
}

.row-space {
  margin-top: 10px;
}

.row-space-2x {
  margin-top: 20px;
}

.row-space-4x {
  margin-top: 40px;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}

.center-pills {
  display: inline-block;
}

/*.menu-audit {*/
/*padding-top: 10px;*/
/*padding-bottom: 10px;*/
/*}*/

.audit-form-controls {
  margin-bottom: 10px;
}

.queryConfigStyle {
  margin-bottom: 3px;
}

.chart {
  position: relative;
  display: block;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
}

.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}

.angular {
  margin-top: 100px;
}

.angular .chart {
  margin-top: 0;
}

.thumbnail .chart-label {
  width: 100%;
  text-align: center;
}

.thumbnail h4 {
  text-align: center;
}

.widget-server .no-margin {
  padding: 5px;
}

.server-thumb hr {
  margin: 10px 0;
}

.sidebar {
  position: fixed;
  background: #384047;
  top: 0;
  bottom: 0;
  z-index: 1031;
}

.oaside-small {
  width: 8% !important;
}

.dashboard-container {
  padding: 20px 40px;
}

.thumbnails {
  list-style: none;
  padding-left: 0px;
}

.table-responsive {
  overflow: auto;
}

.table-fixed {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 500px; /* this can vary */
}

.table-fixed thead {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.table-fixed tbody {
  overflow-y: scroll;
  display: inline-block;
}

.table-fixed thead > tr, .table-fixed tbody > tr, .table-fixed tfoot > tr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.table-fixed thead, .table-fixed tfoot {
  flex-shrink: 0;
}

.table-fixed th, .table-fixed tbody td {
  width: 20%; /* this can vary */
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.table-fixed tfoot {
  display: inline-block;
}

.table-fixed tfoot td {
  width: 100%;
  display: inline-block;
}

.daside-list a.list-group-item {
  text-align: center;
}



[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .ng-hide:not(.ng-hide-animate) {
  display: none !important;
}
